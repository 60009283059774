import React from 'react'
import { Layout } from '../components/AppLayout'
import styled from 'styled-components'
import { Professional } from '../components/Professional'
import { About } from '../components/About'
// import { AppHomeIntelligentSpend } from '../components/AppHomeIntelligentSpend'
// import { AppSolutionByCardCardView } from '../components/AppSolutionByCardCardView'
// import { AppHomeFullyIntegrated } from '../components/AppHomeFullyIntegrated'
// import { AppHomeSimple } from '../components/AppHomeSimple'
// import { AppHomeYourBusiness } from '../components/AppHomeYourBusiness'
// import { AppHomeDesignedby } from '../components/AppHomeDesignedby'
// import AppLandingPageCarousel from '../components/AppLandingPageCarousel'
import { AppHomeByCompanyCard } from '../components/AppHomeByCompanyCard'
import '../styles/global.css'
import { RDHelmet } from '../components/RDHelmet'
import { AppHomeBanner } from '../components/AppHomeBanner'
// import { AppJeffHomeBanner } from '../components/AppJeffHomeBanner'
// import { AppCathyHomeBanner } from '../components/AppCathyHomeBanner'
import { AppHomeByRoleCard } from '../components/AppHomeByRoleCard'
import { AppCustomersReview } from '../components/AppCustomersReview'
import { AppHomeAwardSection } from '../components/AppHomeAwardSection'
// import { AppHomeCustomersReview } from '../components/AppHomeCustomersReview'
// import { AppHomeByFunctionCards } from '../components/AppHomeByFunctionCards'
// import { AppWorkwearOutfittersBanner } from '../components/AppWorkwearOutfittersBanner'

const IndexWrapper = styled.div`
  body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    overflow-x: hidden;
  }
`

export default () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '1-408-899-2224', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' Santa Clara',
      addressRegion: ' 2350 Mission College Boulevard, CA',
      postalCode: '95054',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <IndexWrapper>
        <RDHelmet
          contentDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
          contentKeywords="content='Business spend management software, SaaS spend management software, Enterprise spend management software, Spend management software market, Spend management software companies, Business spend management platform, Artificial intelligence software, AI-based spend management solution'"
          contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
          contentOgDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
          contentOgUrl="https://raindrop.com/"
          schemaMarkup={schemaMarkup}
        />
        {/* <Banner /> */}
        <AppHomeBanner />
        <AppHomeAwardSection />
        {/* <AppHomeByFunctionCards /> */}
        {/* <AppHomeCustomersReview /> */}
        {/* <AppLandingPageCarousel /> */}
        {/* <AppLandingPageCarousel/> */}
        {/* <AppHomeIntelligentSpend /> */}
        {/* <AppHomeFullyIntegrated /> */}
        {/* <AppHomeSimple /> */}
        {/* <AppHomeYourBusiness /> */}
        {/* <AppHomeDesignedby /> */}
        <AppCustomersReview />
        {/* <AppWorkwearOutfittersBanner /> */}
        {/* <AppCathyHomeBanner /> */}
        {/* <AppJeffHomeBanner /> */}
        <AppHomeByCompanyCard />
        <AppHomeByRoleCard />
        {/* <AppSolutionByCardCardView /> */}
        <About />
        <Professional />
      </IndexWrapper>
    </Layout>
  )
}
